<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t(`_.title`) }}</span>
    </template>
    <p class="main-msg">{{ $t(`_.statusMessage`, { title }) }}</p>
    <template v-slot:buttons>
      <button @click="goArena">{{ $t(`_.${realTime ? 'realTimeButton' : 'button'}`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'TournamentEndItem',
  lexicon: 'shared.notification.TOURNAMENT_END',
  components: { NotificationItem },
  props: {
    message: Specific,
  },
  computed: {
    tournament() {
      return this.message?.data?.tournament || {};
    },
    isWcgArena() {
      return this.tournament.isWcgArena;
    },
    title() {
      return this.tournament.title || this.$t('anonymous.arena');
    },
    realTime() {
      return Boolean(this.message?.realTime);
    },
  },
  methods: {
    goArena() {
      const { tournamentId, gameId, isWcgArena } = this.tournament;
      this.routeMainPath({ path: `${this.$i18n('path')}/arena/${isWcgArena ? 'wcg/' : ''}${tournamentId || gameId}/prize` });
      this.$emit('read-item', this.message || {});
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
